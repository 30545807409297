<template>
    <div id="getnodes" v-if="dataLoaded">
        <div>
            <v-row justify="center">
                <v-col md="12">
                    <v-card elevation="5" md="12">
                        <v-card-title>
                            <v-row>
                                <v-col color="accent" md="5">
                                    <strong>{{ $t('menu.buttons.nodes') }}</strong>
                                </v-col>
                                <v-col md="3">
                                    <v-text-field
                                        ref="nodeSearch"
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        class="mt-2"
                                        clearable
                                        dense
                                        hide-details
                                        :label="$t('main.search')"
                                        single-line
                                        solo
                                    />
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <div>
                            <v-data-table
                                :headers="headers"
                                :item-key="nodesList.id"
                                :items="nodesList"
                                :search="search"
                                class="elevation-5"
                                :footer-props="{
                                    itemsPerPageText: $t('main.rowsPerPage'),
                                    pageText: '{0} - {1} ' + $t('main.of') + ' {2}'
                                }"
                                :no-data-text="$t('main.noDataFound')"
                                :no-results-text="$t('main.noSearchResults')"
                            >
                                <template #item.id="{ item }">
                                    <router-link
                                        :to="{ name: 'Dashboard', params: { ctzid: item.id }}"
                                        class="scheduleLink"
                                    >
                                        {{ item.id }}
                                    </router-link>
                                </template>
                                <template #item.user_field="props">
                                    <v-edit-dialog
                                        :return-value.sync="props.item.user_field"
                                        @save="saveUserfield(props)"
                                    >
                                        {{ props.item.user_field }}
                                        <template v-slot:input>
                                            <v-text-field
                                                v-model="props.item.user_field"
                                                :rules="[max25chars]"
                                                label="Edit"
                                                single-line
                                                color="red"
                                                counter
                                            ></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>
                                <template #item.serialNumbers="{ item }">
                                    <template v-if="item.serialNumbers.length <= 40">
                                        {{ item.serialNumbers }}
                                    </template>
                                    <v-tooltip bottom v-else>
                                        <template v-slot:activator="{on,attrs}">
                                            <div v-bind="attrs" v-on="on" @click="copyTextToClipboard(item.serialNumbers)">{{ fixSerialNumbers(item.serialNumbers) }}</div>
                                        </template>
                                        <span>{{ item.serialNumbers }}</span>
                                    </v-tooltip>
                                </template>
                                <template #item.connection.last_seen="{ item }">
                                    <div>
                                        {{ prettyFormatDate(item.connection.last_seen) }}
                                    </div>
                                </template>
                                <template #item.connection.online="{ item }">
                                    <div>
                                        <v-icon :class="{ online: !item.connection.online, offline: item.connection.online }">{{ item.connection.online ? 'mdi-check-circle' : 'mdi-cancel' }}</v-icon>
                                    </div>
                                </template>
                                <template #item.map="{ item }">
                                    <div v-if="item.location">
                                        <v-tooltip bottom>
                                            <template #activator="{ on,attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <router-link :to="{ name: 'Map', params: { centerOnCoords: item.location }}"
                                                >
                                                    <v-icon v-if="item.location.type === 'gps'">mdi-map-marker</v-icon>
                                                    <v-icon v-else>mdi-map-marker-question-outline</v-icon>
                                                </router-link>
                                            </span>
                                            </template>
                                            <span>({{ item.location.latitude }},{{ item.location.longitude }})</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else>
                                        <v-icon>mdi-minus</v-icon>
                                    </div>
                                </template>
                                <template #item.schedule.id="{ item }">
                                    <div v-if="item.schedule.id">
                                        <router-link class="scheduleLink" :to="{name: 'schedNew', params: { crudType: 'edit'}, query: {
                                            schedule_id: item.schedule.id,
                                            schedule_version: item.schedule.version,
                                            io_id: 0,
                                            profile_id: 0
                                        }}">
                                        {{ item.schedule.id }}: {{ item.schedule.name }}
                                        </router-link>
                                    </div>
                                    <div v-else>
                                        <v-icon class>mdi-minus</v-icon>
                                    </div>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {cloneDeep as _cloneDeep} from "lodash";
import {getNodeIds, setUserfield} from "@/services/apiHelpers";
import dayjs from "dayjs";

export default {
    name: "Nodes",
    created() {
        this.populateList();
    },
    data() {
        return {
            search: '',
            dataLoaded: false,
            nodesList: null,
            selectedOrganisation: '',
            max25chars: v => v.length <= 25 || 'Input too long!', // TODO fix for translation
        };
    },
    computed: {
        ...mapGetters({ 'authStatus': 'auth/authStatus', 'stateScheduleList': 'ctz/StateScheduleList', 'activeOrganisation': 'auth/StateActiveOrganisation',
         'onlineNodes': 'ctz/StateOnlineList', 'stateNodeList': 'ctz/StateNodeList', 'stateNodeMeta': 'ctz/StateNodeMeta'}),
        headers() {
            return [
                { id: 1, text: this.$t('menu.buttons.node'), value: 'id'},
                { id: 8, text: 'User Field', value: 'user_field'},
                { id: 2, text: 'Hardware ID', value: 'hardware.hardware_id'},
                { id: 7, text: 'Serial Numbers', value: 'serialNumbers'},
                { id: 3, text: this.$t('main.lastSeen'), value: 'connection.last_seen'},
                { id: 4, text: 'Online', value: 'connection.online' },
                { id: 5, text: this.$t('main.viewOnMap'), value: 'map' },
                { id: 6, text: this.$t('menu.buttons.schedule'), value: 'schedule.id'}
            ];
        },
        activeOrg(){
            return this.activeOrganisation;
        },
    },
    methods: {
        copyTextToClipboard(item){
            navigator.clipboard.writeText(item);
            alert('Serial Numbers copied to Clipboard');
        },
        fixSerialNumbers(data){
            return data.substring(0, 37) + "...";
        },
        prettyFormatDate(data){
            const dateFormat = this.$i18n.locale === 'en' ? 'YYYY-MM-DD @ HH:mm' : 'DD-MM-YYYY @ HH:mm';
            return dayjs(`${data}`, "YYYY-MM-DD[T]HH:mm:ss[Z]").format(dateFormat);
        },
        shortListString(longList){
            let list = [];
            longList.nodes.forEach((element) => list.push(element.substr(15, element.length - 1)));
            return list.join(",");
        },
        isEmpty(obj) {
            return Object.keys(obj).length === 0;
        },
        myMerge(myOnlineNodes, myMetaNodes, myNodeSchedules) {
            let result = [];
            myMetaNodes.forEach((elem) => {
                // an extra field 'serialNumbers' is added to the objects. to show the serial numbers of each module.
                let serials = "";
                elem['hardware'].modules.forEach((module) => {
                    serials += module.serial_number ? module.serial_number + "," : "";
                });
                result.push({
                    ...elem,
                    serialNumbers: serials.substring(0, serials.length - 1),
                    connection: myOnlineNodes.find((el) => el.id === elem.id).connection,
                    schedule: myNodeSchedules.find((ele) => ele.id === elem.id).schedule
                })
            })
            return result;
        },
        async populateList() {
            // check which organisation is the active Organisation
            const org = this.activeOrganisation;
            const constNodeList = await getNodeIds(org, 1);

            if (constNodeList) {
                await Promise.all([
                    this.$store.dispatch('ctz/getNodesOnline', constNodeList),
                    this.$store.dispatch('ctz/getMetaNodes', constNodeList),
                    this.$store.dispatch('ctz/getSchedulesNodes', constNodeList)
                ]);

                const myOnlineNodes = this.onlineNodes;
                const myMetaNodes = this.stateNodeMeta;
                const myScheduleNodes = this.stateScheduleList;
                this.nodesList = _cloneDeep(this.myMerge(myOnlineNodes, myMetaNodes, myScheduleNodes));

            } else {
                // No nodes to display. Don't load the other lists
                this.nodesList = [];
            }
            this.dataLoaded = true;
        },
        saveUserfield(data){
            setUserfield(data.item.id, data.value);
            this.populateList();
        }
    },
    watch:{
        activeOrg(newV, oldV) {
            if(newV !== oldV) {
                this.populateList();
            }
        }
    }
}
</script>

<style scoped>
.online {
    color: red;
}
.offline {
    color: green;
}
.scheduleLink {
    color: #515151;
    text-decoration: none;
}
</style>