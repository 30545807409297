<template>
    <v-container class="relais2-main">
        <div v-if="id === 'NO RELAIS'">
            <v-card width="450">
                <v-card-title>{{ $t('module.noRelaisToShow') }}</v-card-title>
            </v-card>
        </div>
        <div v-else>
            <v-row class="flex-row"  cols="12">
                <v-col class="mt-6" md="4">
                    <v-card class="text-h5 card"
                            lg="8"
                            md="8"
                            width="200"
                    >
                        <v-card-text :style="cardTitle" :title="port">
                            {{ id }}
                        </v-card-text>
                    </v-card>
                    <v-card :style="cardTitle" class="pa-2">
                        {{ $t('module.connected') }}: {{ connected ? $t("main.yes") : $t("main.no") }}
                    </v-card>
                    <v-card :style="cardTitle" class="pa-2">
                        {{ $t('module.errors') }}: {{ errors }}
                    </v-card>

                </v-col>
                <v-col>
                    <div class="sub-title">{{ $t('module.out.1') }}</div>
                    <div :class="cardColor(sub1)" :title="sub1 ? 'ON' : 'OFF'"
                         class=" py-15 px-5 ma-2 rounded">{{ sub1 ? $t('main.onCap') : $t('main.offCap') }}</div>
                </v-col>
                <v-col>
                    <div class="sub-title">{{ $t('module.out.2') }}</div>
                    <div :class="cardColor(sub2)" :title="sub2 ? 'ON' : 'OFF'"
                         class="py-15 px-5 ma-2 rounded">{{ sub2 ? $t('main.onCap') : $t('main.offCap') }}</div>
                </v-col>
                <v-col>
                    <div class="sub-title">{{ $t('module.out.3') }}</div>
                    <div :class="cardColor(sub3)" :title="sub3 ? 'ON' : 'OFF'"
                         class="py-15 px-5 ma-2 rounded">{{ sub3 ? $t('main.onCap') : $t('main.offCap') }}</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="last-seen">(port : {{ port }})</v-col>
                <v-col><div class="last-seen">{{ $t('main.lastSeen') }} : {{ conv_timestamp(lastSeen) }}</div></v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import dayjs from "dayjs";

export default {
    name: "Relais2",
    props: {
        id: {
            type: String,
        },
        port: {
            type: Number,
            default: 0,
        },
        connected: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Number,
            default: 0
        },
        sub1 : {
            type: Number,
            default: 0
        },
        sub2 : {
            type: Number,
            default: 0
        },
        sub3 : {
            type: Number,
            default: 0
        },
        lastSeen: {
            type: String,
            default: '1900-01-01T00:00:00Z'
        }
    },
    mounted() {
        if (!this.id) {
            this.id = "No Relais to display"
        }
    },
    data() {
        return {
            cardTitle: {
                'font-size': '16px',
                'font-weight': 'bold',
                'color': 'black',
                'background-color': 'darkgrey',
                'margin-top': '6px',
            },
            cardData: {
                'font-size': '14px',
                'font-weight': 'bold',
            },
            red: '#BE2830',
        }
    },
    methods: {
        cardColor: function(sub) {
            if (sub === 1) return 'yellowlight';
            else return 'graylight';
        },
        conv_timestamp(mydate){
            if (mydate){
                const dateFormat = this.$i18n.locale === 'en' ? 'YYYY-MM-DD @ HH:mm' : 'DD-MM-YYYY @ HH:mm';
                return dayjs(`${mydate}`, "YYYY-MM-DD[T]HH:mm:ss[Z]").format(dateFormat);
            } else return "";

        },
    },
}
</script>

<style scoped>
.relais2-main {
    background-color: white;
}
.card {
    background-color: #e3e3e3;
    color: black;
}
.card-data {
    background-color: #e3e3e3;
}
.yellowlight {
    column-fill: auto;
    background-color: #ffff8d;
    color: black;
}
.graylight {
    column-fill: auto;
    background-color: lightgray;
    color: white;
}
.greenlight {
    column-fill: auto;
    background-color: green;
    color: white;
}
.redlight {
    column-fill: auto;
    background-color: #BE2830;
    color: white;
}
.last-seen {
    font-size: 12px;
    font-weight: bold;
}
.sub-title {
    text-align: center;
}
</style>