<template>
    <v-row >
    <div class="flex-row">
        <div class="d-inline-flex mx-16 column-one">
            <div class="flex-column">
                <TitleCard class="py-2">
                    <h2>{{ module }}</h2>
                </TitleCard>
                <TitleCard class="py-2">
                    Hardware ID : {{ info.hardware_id }}
                </TitleCard>
                <TitleCard class="py-2" v-if="info.user_field">
                    User field : {{ info.user_field }}
                </TitleCard>
                <!-- check for cityzenz type. only show when ctzii -->
                <div v-if="showFases">

                    <div v-for="fase in fases" :key="fase.id" class="d-inline-flex">
                        <Fase2 :is-title="fase.id === 0" :alert="fase.id > 0 && fase.voltage === 0">
                            <template v-slot:Ftitle>{{ fase.id === 0 ? $t(fase.title) : fase.title }}</template>
                            <template v-slot:Fvoltage>{{ fase.id === 0 ? $t(fase.voltage) : fase.voltage / 1000 }}</template>
                            <template v-slot:Fcurrent>{{ fase.id === 0 ? $t(fase.current) : fase.current }}</template>
                            <template v-slot:Fpower>{{ fase.id === 0 ? $t(fase.power) : fase.power }}</template>
                            <template v-slot:Fcosphi>{{ fase.cosphi }}</template>
                        </Fase2>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isCtznzii" class="d-inline-flex mx-16 column-two">
            <v-col class="flex-column justify-center ml-9">
                <v-row>
                    <v-card width="200" height="64" class="my-4">
                        <div class="text-h3 my-2">CityZenz</div>
                    </v-card>
                </v-row>
                <v-row>
                    <Io2 :auto="io.automatic" :cablefault="io.cablefault" :door="!io.door" :emergency="io.emergency"
                         :online="io.online" :faseErr="io.faseError" :show="io.show"/>
                </v-row>
                <v-row v-if="showSmartMeters">
                    <smart-meters class="mt-6">
                        <template #consumedmeter1>{{ consumed1 }}</template>
                    </smart-meters>
                </v-row>
                <v-row class="mt-6">
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn class="button-disabled" color="gray" width="200" v-on="on" v-bind="attrs">{{ $t('menu.buttons.manualOverride') }}</v-btn>
                        </template>
                        <span>{{ $t('tooltip.manualOverride') }}</span>
                    </v-tooltip>

                </v-row>
            </v-col>

        </div>
        <div class="d-inline-flex mx-16 column-three">
            <div class="flex-column" style="box-shadow: #D3D3D3 3px 4px 5px 1px" v-if="isOnline">
                <v-card-title class="cardbackground">Relais</v-card-title>
                <div v-if="isCtznzii && relais.length > 0 && relais[0].sub1 !== -1" class="flex-row">
                    <Relais v-for="relai in relais" :id="relai.id"
                        :key="relai.id" :port="relai.port" :sub1="relai.sub1"
                            :connected="relai.connected" :errors="relai.errors"
                            :lastSeen="relai.last_seen" :sub2="relai.sub2" :sub3="relai.sub3"
                    ></Relais>
                </div>
                <div v-else class="flex-row">
                    <Relais id="NO RELAIS" :port="0" :sub1="0"
                            :sub2="0" :sub3="0"></Relais>
                </div>
            </div>
        </div>
    </div>
    </v-row>
</template>

<script>
import Fase2 from '@/components/NewModule/Fase2.vue';
import Io2 from "@/components/NewModule/Io2.vue";
import Relais from "@/components/NewModule/Relais2.vue";
import TitleCard from "@/components/NewModule/TitleCard";
import {mapActions, mapGetters} from "vuex";
import SmartMeters from "@/components/NewModule/SmartMeters";
import { cloneDeep as _cloneDeep } from "lodash";

export default {
    name: "Module2",
    components: {
        TitleCard,
        Fase2,
        Io2,
        Relais,
        SmartMeters
    },
    created() {
        this.loggedin = this.isAuthenticated;
        this.getData();

        // refreshes every minute to make sure the data is recent
        this.interval = setInterval(() => this.getData(), 60000);
        this.$on('hook:beforeDestroy', () => clearInterval(this.interval));
    },
    data() {
        return {
            loggedin: null,
            current_url: null,
            isCtznzii: null,
            fases: [
                {
                    id: 0,
                    title: 'module.infoUpper',
                    voltage: 'module.voltage',
                    current: 'module.current',
                    power: 'module.power',
                    cosphi: 'CosPhi',
                    active: 'put true .. if there is a voltage detected.'
                },
                {
                    id: 1,
                    title: 'L1',
                    voltageName: 'V1.mV',
                    voltage: 0.0,
                    currentName: 'I1.mA',
                    current: 0.0,
                    powerName: 'P.L1.W',
                    power: 0.0,
                    cosphi: 0.0,
                    active: false,
                },
                {
                    id: 2,
                    title: 'L2',
                    voltageName: 'V2.mV',
                    voltage: 0.0,
                    currentName: 'I2.mA',
                    current: 0.0,
                    powerName: 'P.L2.W',
                    power: 0.0,
                    cosphi: 0.0,
                    active: false,
                },
                {
                    id: 3,
                    title: 'L3',
                    voltageName: 'V3.mV',
                    voltage: 0.0,
                    currentName: 'I3.mA',
                    current: 0.0,
                    powerName: 'P.L3.W',
                    power: 0.0,
                    cosphi: 0.0,
                    active: false,
                }
            ],
            io: {
                door: false,
                online: false,
                automatic: false,
                cablefault: false,
                emergency: false,
                faseError: false,
                showFaseErr: false,
            },
            relais: [],
            info: {
                hardware_id: '',
                firmware: 'None',
                connected: 0,
                connections: 0,
                type: 'None',
                port: 0,
                mode: 0,
                user_field: ''
            },
            module: 'Test Module Name',
            module_info: null,
            module_info_organ: '',
            module_info_proj: '',
            nrOfProjects: 0,
            nrOfSections: 0,
            module_info_sections: '',
            selectedNode: null,
            consumed1: 0,
            showSmartMeters: false,
            showFases: false,
            faseErrorInfo: '',
            CTZ: null,
        }
    },
    props: {
        ctzid: {
            type: [Number, String],
            default: 0,
            required: true
        }
    },
    computed: {
        ...mapGetters({'isAuthenticated': 'auth/isAuthenticated', 'StateNodeLast': 'ctz/StateNodeLast',
            'StateNodeSingle': 'ctz/StateNodesingle', 'stateTempLast': 'ctz/StateTempLast', 'stateTempMeta': 'ctz/stateTempMeta' }),
        isOnline() {
            return this.selectedNode ? this.selectedNode.connection.online : false;
        }
    },
    methods: {
        ...mapActions({'getNodeData': 'ctz/getData', 'getNodeLastData': 'ctz/getLastData'}),
        async getData(){
            try {
                await this.getNodeData(this.ctzid);
                await this.getNodeLastData(this.ctzid);
                this.isCtznzii = this.StateNodeSingle['hardware']['modules'][0]['type'] === 'czii';
                this.selectedNode = Object.assign({}, this.StateNodeLast, this.StateNodeSingle, {organisation: '', projects: [], sections: []});

                this.update_fases();
                this.update_relais();
                this.update_info();
            } catch(error) {
                console.log('There was an error while loading DATA during getData() in Module2.vue : ' + error);
            }
        },
        update_fases(){
            // get data from the fases
            // const energyMeterPorts = this.selectedNode.hardware.modules.map((module) => {
            //     if (module.type === 'energy_meter' || module.type === 'p1_meter') return module.port_id
            // }).filter((x) => x);
            // console.log(energyMeterPorts); // for the ABB energymeters
            const energyMeterPorts = [248];

            // Later we will also check for 'energy_meter' (for the ABB).
            if (this.selectedNode.hardware.modules.findIndex((module) => module.type === 'p1_meter') !== -1) {
                this.consumed1 = this.selectedNode.sensors.find((sensor) => sensor.port_id === 248).measurements['Energy.Consumed.Wh'] ? this.selectedNode.sensors['Energy.Consumed.Wh'].value : 0;
                this.showSmartMeters = true;

                for (let i = 1; i <= 3; i++) {
                    let phase = this.fases.find((elem) => elem.id === i);

                    // TODO for now we have only the one meter on port 248
                    const sensors = this.StateNodeLast.sensors.find((sensor) => sensor.port_id === energyMeterPorts[0]).measurements;

                    if (sensors[this.fases[i].currentName]) {
                        phase.current = sensors[this.fases[i].currentName].value;
                        phase.active = true;
                        this.showFases = true;
                    }
                    if (sensors[this.fases[i].voltageName]) {
                        phase.voltage = sensors[this.fases[i].voltageName].value;
                        phase.active = true;
                        this.showFases = true;
                    }
                    if (sensors[this.fases[i].powerName]) {
                        phase.power = sensors[this.fases[i].powerName].value;
                        phase.active = true;
                        this.showFases = true;
                    }

                }
                if (!this.fases[1].active || !this.fases[2].active || !this.fases[3].active) {
                    this.faseErrorInfo = "There is an error on one of the Fases!";
                    this.showFaseErr = true;
                    this.io.faseError = true;
                }
            }
        },
        update_relais(){
            // get data from cityzenzi's
            this.CTZ = { czii: null, czi: []};

            const czii = this.StateNodeSingle['hardware'].modules.find((mod) => mod.type === 'czii');
            this.CTZ.czii = {...czii};

            for (let module of this.StateNodeSingle['hardware'].modules) {
                if (module.type === 'czi') {
                    this.CTZ.czi.push(module);
                }
            }

            const cziCount = this.CTZ.czi.length;

            // Loop through the czi's
            for (let i = 0; i < cziCount; i++){
                const port1 = this.CTZ.czi[i].port_id;
                const outputs = this.StateNodeLast['ios'].outputs;
                const status = this.StateNodeLast.status;
                const connection = this.StateNodeLast.connection;

                const Relais = {
                    id: `czi ${i + 1}`,
                    port: port1,
                    active: connection.online,
                    serial_number: this.CTZ.czi[i].serial_number,
                    connected: status[`connected.${port1}`],
                    firmware: this.StateNodeSingle['hardware'].modules[i + 1].firmware,
                    errors: status[`errors.${port1}`],
                    last_seen: connection.last_seen,
                    sub1: outputs[`out.${(i * 3) + 1}`] ? outputs[`out.${(i * 3) + 1}`].value : -1,
                    sub2: outputs[`out.${(i * 3) + 2}`] ? outputs[`out.${(i * 3) + 2}`].value : -1,
                    sub3: outputs[`out.${(i * 3) + 3}`] ? outputs[`out.${(i * 3) + 3}`].value : -1,
                }

                // Check if this actually works
                if (this.relais.find((relai) => relai.port === port1)){
                    let index = 0;
                    this.relais.forEach((e) => {
                        if (e.port === port1) {
                            this.relais[index] = _cloneDeep(Relais);
                        }
                        index++;
                    })
                } else {
                    this.relais.push(Relais);
                }
            }
        },
        update_info(){
            this.module = `${this.selectedNode.id} (${this.CTZ.czii.type})`;
            this.isCtznzii = this.CTZ.czii.type === 'czii';

            this.info['firmware'] = this.StateNodeSingle['hardware']['modules'][0].firmware;
            this.info['type'] = this.StateNodeSingle['hardware']['modules'][0].type;
            this.info['hardware_id'] = this.StateNodeSingle['hardware'].hardware_id;
            this.info['user_field'] = this.StateNodeSingle.user_field;

            const doorIO = Object.keys(this.StateNodeLast['ios'].inputs).length > 0;
            this.io.door = doorIO ? this.StateNodeLast['ios'].inputs[`in.1`].value === 1 : 1;

            this.io['online'] = this.StateNodeLast.connection.online;

            // automatic is CTZNZmode and will be implemented in backend.
            this.io['automatic'] = true; // 0 = production, 1 = manual, 2 = test
            // cablefault is from status.errors (bitwise AND with 0x20)
            this.io['cablefault'] = (this.selectedNode.status[`errors.${this.CTZ.czii.port_id}`] & 0x20);
            this.io['emergency'] = false;
        },

    }
}
</script>
<style>
.cardbackground {
    background-color: #E3E3E3;
}
.column-one {
    width: 500px;
}
.column-two {
    width: 300px;
}
.column-three {
    width: 550px;
}
.button-disabled {
    cursor: default;
}
</style>
