<template>
    <div>
        <v-card
            class="cardtitle"
            height="80"
            width="490"
        >
            <v-card-title class="text-md-h5 cardtitle">
                <slot></slot>
            </v-card-title>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "TitleCard",
}
</script>

<style scoped>
.cardtitle {
    color: #636363;
    font-weight: bold;
    background-color: #E3E3E3;
}
</style>