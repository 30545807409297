<template>
<div>
    <v-card class="module-card" width="200">
        <v-card-title class="pa-2 module-title cardbackground">
            {{ $t('module.smartMeter') }}
        </v-card-title>
        <v-card class="pa-2 my-2 text-center">
            <slot name="consumedmeter1"></slot> Wh
        </v-card>
    </v-card>
</div>
</template>

<script>
export default {
    name: "SmartMeters"
}
</script>